import React from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DotMatrix, BlogCardBlock, CTA, SEO, Button } from "../components";
import { getSnippetFromHtml } from "../utils/text-utils";

const proseStyles = css`
  & * {
    ${tw`prose! prose-lg!`}
  }
  & p,
  & p > span,
  & blockquote,
  & blockquote > span {
    ${tw`text-gray-500!`}
  }
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`text-gray-800!`}
  }
`;

const JobTemplate = ({ data: { strapiJobs } }) => {
  const {
    content,
    image,
    role,
    slug,
    location: locationText,
    contactEmail,
  } = strapiJobs;
  return (
    <>
      <SEO
        title={`Job - ${role} - ${locationText}`}
        description={getSnippetFromHtml(content)}
        previewImage={{ src: image.localFile.publicURL }}
      />
      <main>
        <section tw="relative py-16 bg-white overflow-hidden">
          <DotMatrix side="right" positioning={tw`top-12`} />
          <DotMatrix side="left" positioning={tw`top-1/2`} />
          <div tw="relative mx-auto max-w-prose px-4 sm:px-6 lg:px-8">
            <div tw="text-lg max-w-prose mx-auto">
              <span tw="block font-semibold text-base text-denim-600 text-center tracking-wide uppercase">
                Careers
              </span>
              <h1 tw="mt-2 block text-5xl text-center font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                {role}
              </h1>
              <p tw="mt-8 text-xl text-gray-500 leading-8 text-center">
                {locationText}
              </p>
            </div>
            <div tw="mt-20 mx-auto text-gray-500">
              <figure tw="rounded-lg">
                <GatsbyImage
                  image={getImage(image.localFile)}
                  alt={
                    image.alternativeText ||
                    image.caption ||
                    `${role} hero image`
                  }
                  tw="w-full rounded-lg"
                  imgStyle={{
                    borderRadius: "0.5rem",
                    marginTop: 0,
                    marginBottom: 0,
                  }}
                />
                {!!image.caption && (
                  <figcaption tw="text-center">{image.caption}</figcaption>
                )}
              </figure>
              <div tw="prose prose-lg max-w-prose mt-20 mx-auto">
                <div
                  className="strapi-html-content"
                  css={[proseStyles]}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </div>
            <Button
              text="Apply Now"
              colour="lighter"
              href={`mailto:${contactEmail}?subject=${role} Application`}
              target="_blank"
              rel="noopener noreferrer"
              customStyles={tw`block mt-8`}
            />
          </div>
        </section>
        <BlogCardBlock slug={`/careers/${slug}`} />
        <CTA />
      </main>
    </>
  );
};

export default JobTemplate;

export const query = graphql`
  query jobsTemplateQuery($id: String!) {
    strapiJobs(id: { eq: $id }) {
      contactEmail
      content
      updated_at
      location
      slug
      role
      image {
        alternativeText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
